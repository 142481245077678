<template>
  <div
    @click="clearSelectedElement"
  >
    <h2>Merge Case Video -
      <router-link :to="'/cases/' + caseObject.CaseID + '/detail'">
        <span class="pl-1 text-nowrap">{{ caseObject.FileNumber }}</span>
      </router-link>
    </h2>
    <hr>
    <b-row class="pb-2">
      <b-col>
        <v-select
          id="Action"
          v-model="Action"
          label="title"
          :options="actionsOption"
          placeholder="Select Action"
          name="action"
          :disabled="SelectedVideos.length < 2"
        />
      </b-col>
      <b-col>
        <v-select
          id="Activity"
          v-model="Activity"
          label="title"
          :options="activityOption"
          placeholder="Select Activity"
          name="activity"
          :disabled="SelectedVideos.length < 2"
        />
      </b-col>
      <b-col>
        <b-button
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          type="button"
          :variant="'primary'"
          class="mr-1"
          :disabled="SelectedVideos.length < 2 || !Activity"
          @click.prevent="mergeVideos()"
        >
          Merge Files
        </b-button>
      </b-col>
    </b-row>
    <b-row>
      <!-- Table -->
      <b-col class="white-bg-container" md="6">
        <b-row>
          <b-col class="text-center">
            Clip/View
          </b-col>
          <b-col md="5">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              type="button"
              :variant="'primary'"
              class="move-all text-nowrap"
              :disabled="!Videos.length"
              @click.prevent="moveAllToMerge()"
            >
              Move all video to right
              <feather-icon
                icon="ArrowRightIcon"
                size="21"
              />
            </b-button>
          </b-col>
        </b-row>
        <draggable
          v-model="Videos"
          :move="onMove"
          v-bind="dragOptions"
          @start="isDragging=true"
          @end="isDragging=false"
          class="scroll-block"
        >
          <b-row
            v-for="(item, index) of Videos"
            :key="index"
          >
            <b-col>
              <b-row class="pt-1 mr-1 ml-1 border-top border-right">
                <b-col>
                  <div
                    class="video-container"
                    @contextmenu.capture.prevent="contextMenu($event)"
                  >
                    <img :src="'https://api.nimbushd.com/Imaging/Thumbnail.ashx?id=' + item.VideoID">
                  </div>
                  <div class="d-inline-block position-absolute">
                    <div>
                      <router-link :to="'/cases/view-activity/' + item.Meta.ActivityID + '/VIDEO'">
                        <span class="pl-1 text-nowrap">{{ item.Title }}</span>
                      </router-link>
                    </div>
                    <div class="pl-1">
                      {{ item.Meta.ScheduledDate | dateFormat }}
                    </div>
                    <div class="pl-1">
                      {{ item.DurationMs | minutesFormat }}
                    </div>
                    <div class="pl-1">
                      Clip Order: {{ item.Meta.Clip_Order }}
                    </div>
                  </div>
                  <div
                    title="Click to move this on Merge"
                    class="arrow-icon"
                    @click="addToMerge(item)"
                  >
                    <feather-icon
                      icon="ArrowRightIcon"
                      size="21"
                    />
                  </div>
                </b-col>
              </b-row>
            </b-col>
          </b-row>
        </draggable>
      </b-col>
      <b-col class="white-bg-container">
        <b-row>
          <b-col class="text-center">
            Video to Merge
          </b-col>
        </b-row>
        <div
          v-if="!SelectedVideos.length && Videos.length"
          class="empty-block"
        >
          <h2>Drag the Video here</h2>
        </div>
        <draggable
          v-model="SelectedVideos"
          :move="onMove"
          v-bind="dragOptions"
          class="scroll-block"
        >
          <b-row
            v-for="(item, index) of SelectedVideos"
            :key="index"
          >
            <b-col>
              <b-row class="pt-1 ml-1 mr-1 border-top border-left">
                <b-col>
                  <div
                    class="video-container"
                    @contextmenu.capture.prevent="contextMenu($event)"
                  >
                    <img :src="'https://api.nimbushd.com/Imaging/Thumbnail.ashx?id=' + item.VideoID">
                  </div>
                  <div class="d-inline-block position-absolute">
                    <div>
                      <router-link :to="'/cases/view-activity/' + item.Meta.ActivityID + '/VIDEO'">
                        <span class="pl-1 text-nowrap">{{ item.Title }}</span>
                      </router-link>
                    </div>
                    <div class="pl-1">
                      {{ item.Meta.ScheduledDate | dateFormat }}
                    </div>
                    <div class="pl-1">
                      {{ item.DurationMs | minutesFormat }}
                    </div>
                    <div class="pl-1">
                      Clip Order to Merge: {{ index + 1 }}
                    </div>
                  </div>
                  <div
                    v-if="index !== 0"
                    title="Click to move this Up"
                    class="arrow-icon arrow-up"
                    @click="changeClipOrder(index, true)"
                  >
                    <feather-icon
                      icon="ArrowUpIcon"
                      size="21"
                    />
                  </div>
                  <div
                    title="Click to remove this from the Merge"
                    class="arrow-icon"
                    @click="removeToMerge(item)"
                  >
                    <feather-icon
                      icon="ArrowLeftIcon"
                      size="21"
                    />
                  </div>
                  <div
                    v-if="index !== (SelectedVideos.length - 1)"
                    title="Click to move this Down"
                    class="arrow-icon arrow-down"
                    @click="changeClipOrder(index, false)"
                  >
                    <feather-icon
                      icon="ArrowDownIcon"
                      size="21"
                    />
                  </div>
                </b-col>
              </b-row>
            </b-col>
          </b-row>
        </draggable>
      </b-col>
    </b-row>
  </div>
</template>

<script>

import APIService, {axiosInc} from "@core/utils/APIService";
import {BButton, BCol, BRow} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import {mapMutations} from "vuex";
import vSelect from "vue-select";
import draggable from 'vuedraggable'
import ToastificationContent from "@core/components/toastification/ToastificationContent";

const apiService = new APIService();

export default {
  components: {
    BRow,
    BCol,
    BButton,
    vSelect,
    draggable,
  },
  directives: {
    Ripple,
  },
  filters: {
    minutesFormat(val) {
      const minutes = Math.floor(val / 1000 / 60);
      const seconds = Math.floor(val / 1000) - minutes * 60;
      const minutesTxt = minutes === 1 ? " minute " : " minutes "
      const secondsTxt = minutes === 1 ? " second " : " seconds "
      return minutes + minutesTxt + seconds + secondsTxt;
    },
    dateFormat(val) {
      if (val) {
        const date = new Date(val);
        const options = {month: '2-digit', day: '2-digit', year: 'numeric'}
        if (date) return date.toLocaleDateString("en-US", options);
      }
      if (val) return val.substr(0, 10);
      return null
    },
  },
  data() {
    return {
      CaseID: "",
      caseObject: {},
      Videos: [],
      SelectedVideos: [],
      stopVideo: false,

      actionsOption: [],
      Action: null,
      activityOption: [],
      Activity: null,

      TotalVideos: 0,
      TotalDuration: 0,
      CaptureFrame: false,

      oldRequest: null,
      isDragging: false,
      delayedDragging: false,
      selectedElement: null,
    }
  },
  computed: {
    dragOptions() {
      return {
        animation: 0,
        group: "description",
        disabled: false,
        ghostClass: "ghost"
      };
    },
  },
  watch: {
    Action(val, oldVal) {
      this.activityOption = [];
      this.Activity = null;
      if (val && (!oldVal || val.value !== oldVal.value)) {
        this.loadActivities(val.value)
      }
    },
    isDragging(newValue) {
      if (newValue) {
        this.delayedDragging = true;
        return;
      }
      this.$nextTick(() => {
        this.delayedDragging = false;
      });
    },
    SelectedVideos(val) {
      if (val.length < 2) {
        this.Action = null;
        this.Activity = null;
      }
    }
  },
  created() {
    this.CaseID = this.$route.params.caseId;
    this.loadCase();
    this.loadActions();
    this.loadVideo();
  },
  methods: {
    ...mapMutations({
      setVideoTime: "video/setVideoTime"
    }),

    showToast(variant, position, timeout, data) {
      this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Notification',
              icon: 'InfoIcon',
              text: data,
              variant,
            },
          },
          {
            position,
            timeout,
          })
    },

    loadCase() {
      apiService
          .get("case/" + this.CaseID + "/detail")
          .then((res) => {
            this.caseObject = res.data;
          })
    },

    loadActions() {
      apiService
          .get("actions/case/" + this.CaseID)
          .then((res) => {
            this.actionsOption = res.data.map(i => ({
              title: i.ActionFileNumber,
              value: i.ActionID,
              scheduledDate: i.ScheduledDate
            }))
            this.actionsOption.sort((a, b) => a.scheduledDate > b.scheduledDate ? 1 : -1)
          })
    },

    loadActivities(actionID) {
      const signal = axiosInc.CancelToken.source();
      const request = { cancel: signal.cancel, msg: "Loading..." };
      if (this.oldRequest) this.oldRequest.cancel(this.oldRequest.msg);
      this.oldRequest = request;

      apiService
          .get("action/" + actionID + "/detail", {cancelToken: signal.token})
          .then((res) => {
            this.activityOption = res.data.Activities.map(i => ({
              title: i.ActivityType + " - " + i.Fullname,
              value: i.ActivityID,
            }))
          })
    },

    loadVideo() {
      apiService
          .get("case/" + this.CaseID + "/videos")
          .then((res) => {
            this.TotalDuration = 0;
            this.Videos = res.data;
            this.Videos = this.Videos.map((i, index) => { return {...i, index: index + 1}})
            for (const i in this.Videos) {
              this.TotalDuration = this.TotalDuration + this.Videos[i].DurationMs;
            }
            this.TotalVideos = this.Videos.length;
            this.SelectedVideos = [];
          })
    },

    mergeVideos() {
      this.showToast('success', 'top-center', 4000, 'Merge submission to Nimbus');
      this.loadVideo()
      this.Action = null;
      this.Activity = null;
    },

    onMove({ relatedContext, draggedContext }) {
      const relatedElement = relatedContext.element;
      const draggedElement = draggedContext.element;
      return (
          (!relatedElement || !relatedElement.fixed) && (!draggedElement || !draggedElement.fixed)
      );
    },

    contextMenu(e) {
      e.preventDefault()
      if (this.selectedElement) this.selectedElement.classList.remove("scale");
      this.selectedElement = e.target
      this.selectedElement.classList.add("scale");
    },

    clearSelectedElement() {
      if (this.selectedElement) this.selectedElement.classList.remove("scale");
      this.selectedElement = null
    },

    addToMerge(item) {
      const removedItem = this.Videos.filter(i => i.VideoID === item.VideoID)[0];
      this.Videos = this.Videos.filter(i => i.VideoID !== item.VideoID);
      this.SelectedVideos.push(removedItem)
    },

    removeToMerge(item) {
      const removedItem = this.SelectedVideos.filter(i => i.VideoID === item.VideoID)[0];
      this.SelectedVideos = this.SelectedVideos.filter(i => i.VideoID !== item.VideoID);
      this.Videos.push(removedItem)
    },

    changeClipOrder(index, moveToUp = false) {
      if (moveToUp && index >= 1) {
        this.SelectedVideos.splice(index - 1, 0, this.SelectedVideos.splice(index, 1)[0]);
      } else if (index < this.SelectedVideos.length) {
        this.SelectedVideos.splice(index + 1, 0, this.SelectedVideos.splice(index, 1)[0]);
      }
    },

    moveAllToMerge() {
      let item = null;
      while (item = this.Videos.shift()) {
        if (item) {
          this.SelectedVideos.push(item)
        }
      }
    },
  }
}
</script>

<style scoped>
.video-content img,
.video-container img {
  width: inherit;
  height: inherit;
}
.video-container {
  display: inline-block;
  background-color: grey;
  height: 9rem;
  width: 16rem;
  position: relative;
  top: -0.5rem;
}
.empty-block {
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #ebe9f1;
  height: 11rem;
  margin-bottom: -5rem;
}
.ghost {
  opacity: 0.5;
  background: #c8ebfb;
}
.video-container.scale > img {
  scale: 3;
  position: fixed;
  top: 50%;
  left: 50%;
  z-index: 2;
  transition-duration: 0.5s;
}
img {
  pointer-events: none;
}
.scroll-block {
  max-height: 74vh;
  overflow-x: hidden;
  overflow-y: auto;
  height: 100%;
}
.arrow-icon {
  position: absolute;
  right: 1rem;
  top: 3rem;
  border: 1px solid;
  border-radius: 50%;
  padding: 0.2rem;
  cursor: pointer;
}
.arrow-icon.arrow-up {
  top: 0;
}
.arrow-icon.arrow-down {
  top: 6rem;
}
.move-all {
  display: flex;
  height: 1.8rem;
  justify-content: center;
  align-items: center;
  padding: 0.2rem !important;
  transform: translateY(-0.3rem);
  float: right;
}
</style>